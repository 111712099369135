import React, { useEffect, useState } from "react";
import { graphql } from "gatsby";

import Layout from "../components/layout";
import SEO from "../components/seo";
import CustomStructuredText from "../components/customStructuredText"

const BlogPost = ({ data }) => {

  const { title, content, featuredImage, seo, displayAffiliateNotice } = data.datoCmsArticle;
  return (
    <Layout>
      <SEO title={title} description={seo.description} />
      <div className="blog-post container">
        <h1>{title}</h1>
        <img className="featured-image" alt={featuredImage.title} title={featuredImage.title} src={featuredImage.fluid.src} />
        {displayAffiliateNotice && <p>This article contains affiliate links that benefit mtgevent.com</p>}
        <CustomStructuredText
          data={content}
          renderBlock={({ record }) => {
            switch (record.__typename) {
              case "DatoCmsAmazonNativeShoppingGrid":
                return (
                  <div id="amazon-ad"></div>
                );
              default:
                return null;
            }
          }}
        />
      </div>
    </Layout>
  );
};

export default BlogPost;

export const pageQuery = graphql`
  query($slug: String!) {
    datoCmsArticle(slug: { eq: $slug }) {
      title
      slug
      displayAffiliateNotice
      seo {
        description
        title
      }
      featuredImage {
        title
        fluid(maxWidth: 800) {
          src
        }
      }
      content {
        value
        blocks {
        	__typename
          ... on DatoCmsAmazonNativeShoppingGrid {
            id: originalId
            asins
            title
            linkid
          }
        }
      }
    }
  }
`;
